.certificates-list {
  height: 55vh;
  width: 26vw;
  border: 1px #b5bebe solid;
  border-radius: 20px;
  padding: 5px;
  overflow: hidden;
}
.overflow-scroll-content {
  overflow-y: scroll;
  height: 55vh;
  width: 25.5vw;
  padding-right: 5px;
}
.certificates-cell-container {
  border: 1px #b5bebe solid;
  border-radius: 20px;
  margin-bottom: 1.25vh;
  padding: 10px;
}

.certificates-cell-container:hover {
  border: 1px cadetblue solid;
  cursor: pointer;
}

.certificates-cell-header-container {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

#dark .certificates-cell-title {
  color: white;
}

#light .certificates-cell-title {
  color: #13314d;
}

.certificates-cell-title {
  font-size: 1em;
  text-align: center;
  margin-top: 1vh;
}
.certificates-cell-sub-title {
  color: cadetblue;
  font-size: 0.8em;
  text-align: center;
}

.view-certificate-button {
  background-color: cadetblue;
  border-radius: 20px;
  height: 40px;
  margin-bottom: 10px;
}

.view-certificate-text {
  color: white;
  font-size: 0.8em;
  text-align: center;
  padding: 10px;
}

.body-sub-heading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.body-sub-heading-divider {
  width: 50%;
  border: 1px #b5bebe solid;
  justify-self: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

#light .body-sub-heading-text {
  color: #13314d;
}
#dark .body-sub-heading-text {
  color: whitesmoke;
}
.body-sub-heading-text {
  font-size: 0.75em;
  text-align: center;
}

.accordion-button {
  background-color: transparent !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}

.accordion-button::after {
  display: none;
}
