p {
  text-transform: uppercase;
  color: cadetblue;
}

:root {
  --light: #13314d;
  --dark: white;
}

.navigation-buttons-menu {
  transition: opacity 200ms ease-in-out 100ms;
}

.theme-toggle-btn-round:hover {
  cursor: pointer;
}

.theme-toggle-btn-round {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: cadetblue;
  overflow: hidden;
  animation: fadeIn 2s ease-in;
}

.theme-toggle-btn-round-content {
  margin-top: 5px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.theme-mode-icon-up-animation {
  animation-name: goUp;
  animation-duration: 1s;
  transform: translateY(-30px);
}

.theme-mode-icon-down-animation {
  animation-name: goDown;
  animation-duration: 1s;
  transform: translateY(0px);
}

@keyframes goUp {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-30px);
  }
}

@keyframes goDown {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
