.App {
  min-height: 100vh;
  height: "100%";
  transition: background-color 1s;
  -webkit-transition: background-color 1s;
}

#light {
  background-color: whitesmoke;
}

#dark {
  background-color: #13314d;
}

* {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

#light ::selection {
  color: white;
  background: #13314d;
}

#dark ::selection {
  color: white;
  background: cadetblue;
}
