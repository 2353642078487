.contact-page-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  margin-left: 130px;
  margin-right: 130px;
  padding-top: 50px;
}

.social-icons-container {
  display: flex;
  width: 20vw;
  justify-content: space-evenly;
}

.vertical-flex {
  flex-direction: column;
}

.form-container {
  padding: 20px;
  border: 1px solid #b5bebe;
  border-radius: 20px;
  width: 30vw;
}

.form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

#dark .form-label {
  color: white;
}
#light .form-label {
  color: #13314d;
}

.form-label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: start;
}

.form-input:focus {
  outline: none;
  border: 2px solid cadetblue;
}

#dark .form-input {
  color: aliceblue;
}
#light .form-input {
  color: #13314d;
}

.form-input {
  border: 2px solid #b5bebe;
  border-radius: 5px;
  background-color: transparent;
  outline-color: cadetblue;
  padding: 5px;
}

.form-input-message {
  height: 20vh;
}

.form-submit-button {
  background: #43a8a2;
  border-radius: 20px;
  width: 100%;
  border: 0px;
  color: aliceblue;
  height: 5vh;
  font-size: larger;
  text-transform: uppercase;
}

.social-icons {
  height: 90%;
  aspect-ratio: 1;
}

#dark .about-harsh-text {
  color: white;
}
#light .about-harsh-text {
  color: #13314d;
}
.about-harsh-text {
  font-size: 0.8rem;
  text-align: center;
}
