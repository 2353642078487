#dark .title {
  color: cadetblue;
}
#light .title {
  color: cadetblue;
}
.title {
  font-size: 48px;
  text-align: center;
  margin-right: 20px;
}

#light .project-arrow-icons {
  fill: #13314d;
}
#dark .project-arrow-icons {
  fill: white;
}
