.project-card-container {
  aspect-ratio: 1.8;
  justify-content: center;
}

p {
  text-transform: none;
}

.main-image {
  width: 50%;
  /* aspect-ratio: 1; */
  align-self: center;
}

.card_overlay {
  background-color: #c4c4c4e6;
  opacity: 0;
  transition: opacity 200ms ease-out 100ms;
}

.card_overlay:hover {
  opacity: 1;
  cursor: pointer;
}

.card_overlay_content {
  align-items: center;
  justify-content: center;
}

.project-card-technologies {
  display: flex;
  width: 100%;
  justify-content: center;
}

.project-name {
  text-align: center;
  font-size: 1.4em;
}
