.content {
  display: flex;
  margin-top: -8vh;
  flex: 1;
  justify-content: space-around;
  align-items: center;
}
.education-box {
  height: 250px;
  width: 500px;
  border: 1px #b5bebe solid;
  border-radius: 20px;
  overflow: hidden;
}
.year-cell-container {
  display: flex;
  width: fit-content;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  margin-top: 3.5%;
  padding-bottom: 3.5%;
  padding: 20px;
}
.year-cell-dotted-border {
  height: 10vh;
  margin-top: -58px;
  margin-left: 20px;
  z-index: -1;
  position: absolute;
  border: 1px #c4c4c4 dashed;
}
.year-cell {
  width: fit-content;
  height: 20px;
  border-radius: 20px;
  background-color: cadetblue;
  margin-right: 10px;
  padding: 2px 10px 3px 10px;
}
.year-cell-text {
  color: white;
  font-size: 0.65em;
}
.year-cell-content {
  flex-direction: row;
  display: flex;
}
.year-content-container {
  flex-direction: column;
  display: flex;
}

#dark .institute-name-text {
  color: #e7e7e7;
}
#dark .institute-name-text:hover {
  color: white;
}
#light .institute-name-text {
  color: #0c1e30;
}
#light .institute-name-text:hover {
  color: #4a5663;
}

.institute-name-text {
  font-size: 0.85em;
  line-height: 1.2em;
  margin-bottom: 2px;
  text-align: start;
}

.institute-name-text:hover {
  cursor: pointer;
}

.degree-text {
  color: cadetblue;
  font-size: 0.75em;
  line-height: 0.75em;
  text-align: start;
}

#dark .content-text {
  color: white;
}
#light .content-text {
  color: #13314d;
}

.content-text {
  font-size: 0.8em;
  /* line-height: 0.2em; */
  text-align: start;
}
.sub-headings {
  color: cadetblue;
  font-size: 1.2em;
  line-height: 1.2em;
  text-align: center;
  text-transform: uppercase;
}
