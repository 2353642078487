::-webkit-scrollbar {
  width: 0.3vw;
}

::-webkit-scrollbar-thumb {
  background: cadetblue;
  border: 4px solid transparent;
  border-radius: 12px;
}

#dark .a {
  color: aliceblue;
  font-size: medium;
}
#light .a {
  color: #13314d;
  font-size: medium;
}

.elements {
  position: absolute;
}

.hs-logo {
  width: 4vw;
  height: 5vh;
}
.hs-logo-container {
  position: absolute;
  top: 3vh;
  left: 3vh;
  z-index: 11;
}
.hs-logo-container :hover {
  cursor: pointer;
}
.pagination-dot {
  height: 10px;
  width: 10px;
  border-radius: 5px;
  z-index: 10;
}
.pagination-dot:hover {
  cursor: pointer;
}
