.modal-content {
  height: 80vh;
  border-radius: 60px !important;
  background: rgba(252, 252, 252, 0.9);
  overflow: hidden;
}

.modal-body-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  flex: 1;
  overflow: auto;
}

.project-modal-separator {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  border-top: 3px rgb(127, 136, 136) solid;
  border-radius: 20px;
}
.project-check-it-out-container {
  display: flex;
  justify-content: center;
}

.project-check-it-out-text {
  color: darkslategray;
  font-size: 1.1em;
}

.project-check-it-out-item:hover {
  cursor: pointer;
}

.project-title {
  color: #43a8a2;
  text-align: center;
}
.project-sub-title {
  color: #398984;
  font-size: 1em;
}
.section-title {
  color: #43a8a2;
  font-size: 2em;
}

.project-modal-technologies {
  display: flex;
  width: 100%;
  justify-content: center;
}

.modal-content-text {
  margin-top: 50px;
  align-self: flex-start;
}

.summary-text {
  font-size: 1.1em;
  color: darkslategray;
}

.sections {
  margin-top: 30px;
}

.section-content {
  font-size: 1.1em;
  color: darkslategray;
}

.project-modal-hyperlink:link {
  color: cadetblue;
}
.project-modal-hyperlink:visited {
  color: cadetblue;
}
.project-modal-hyperlink:hover {
  color: rgb(65, 108, 110);
}
.project-modal-hyperlink:active {
  color: cadetblue;
}
