:root {
  --desktop: 1200px;
  --laptop: 1024px;
  --tablet: 768px;
  --phone: 480px;
}

.container-stack {
  justify-content: center;
}

#dark h1 {
  color: white;
}

#dark h3 {
  color: white;
}

#light h1,
h3 {
  color: #13314d;
}

.intro-title-text {
  font-size: 8em;
  text-align: start;
}
.intro-subtitle-text {
  font-size: 4em;
  text-align: start;
}

.intro-text {
  animation-name: fadeInDown;
  animation-duration: 3s;
}

.intro-cells {
  animation-name: fadeInRight;
  animation-duration: 3s;
}

.bubbles {
  display: flex;
  flex-direction: row;
  @media (max-width: 720px) {
    flex-direction: column;
  }
}
.introduction-content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 130px;
  padding-top: 25vh;
}

.intro-cells-container {
  display: flex;
  margin: 10px 0
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-10vh);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(10vw);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
