.bubble {
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  height: 50px;
  align-items: center;
  margin-right: 32px;
  transition: height 0.5s ease-in-out;
  -webkit-transition: height 0.5s ease-in-out;
  overflow: hidden;
  padding: 1em;
  padding-top: 0.8em;
  cursor: default;
}

#light .bubble-text {
  color: white;
}
#dark .bubble-text {
  color: cadetblue;
}

#light .bubble {
  background-color: cadetblue;
}

#dark .bubble {
  background-color: white;
}

.bubble-hover:hover {
  height: 100%;
  
}

.intro-bubble-technologies {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 200px;
  flex-wrap: wrap;
}
h5 {
  font-size: 1em;
  text-align: "center";
}

#light .technology-icon {
  fill: white;
}
#dark .technology-icon {
  fill: cadetblue;
}

.technology-icon {
  height: 40px;
  width: 40px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}
