.custom-nav-item {
  width: 100%;
  padding: 5pt;
  padding-top: 10pt;
  padding-bottom: 10pt;
  display: flex;
  align-items: center;
  border-radius: 7pt;
}

#dark .custom-nav-item .custom-nav-link {
  color: whitesmoke;
}
#light .custom-nav-item .custom-nav-link {
  color: #13314d;
}

.custom-nav-item .custom-nav-link {
  text-decoration: none;
  text-align: center;
  width: 100%;
}

#dark .custom-nav-item:hover .custom-nav-link:not(:hover) {
  text-decoration: none;
  color: cadetblue;
  text-align: center;
}
#light .custom-nav-item:hover .custom-nav-link:not(:hover) {
  text-decoration: none;
  color: cadetblue;
  text-align: center;
}

#dark .custom-nav-link:hover {
  text-decoration: none;
  color: cadetblue;
  cursor: pointer;
}
#light .custom-nav-link:hover {
  text-decoration: none;
  color: cadetblue;
  cursor: pointer;
}

#dark .custom-nav-item-active .custom-nav-link {
  color: whitesmoke;
  text-decoration: none;
  text-align: center;
  width: 100%;
}
#light .custom-nav-item-active .custom-nav-link {
  color: whitesmoke;
  text-decoration: none;
  text-align: center;
  width: 100%;
}

.custom-nav-item-active {
  background-color: cadetblue;
  width: 100%;
  padding: 5pt;
  padding-top: 10pt;
  padding-bottom: 10pt;
  display: flex;
  align-items: center;
  border-radius: 7pt;
}

#light .experience-content-tab-unordered-list-items {
  color: #13314d;
  font-size: 1.2vw;
}
#dark .experience-content-tab-unordered-list-items {
  color: white;
  font-size: 1.2vw;
}
